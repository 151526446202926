// browser may support localStorage, but not make it available to the scripts on the page

import { useEffect, useState } from 'react'

// https://gist.github.com/paulirish/5558557
const checkLocalStorageAvailable = () => {
    try {
        const x = '__storage_test__'

        window.localStorage.setItem(x, x)
        window.localStorage.removeItem(x)
        return true
    } catch (_e) {
        return false
    }
}

let isLocalStorageAvailable: boolean | null = null
const getLocalStorageAvailable = (): boolean => {
    if (isLocalStorageAvailable === null) {
        isLocalStorageAvailable = checkLocalStorageAvailable()
    }

    return isLocalStorageAvailable
}

type webStorageType = 'localStorage' | 'sessionStorage'
function getWebStorageObj(type: webStorageType): Storage {
    return type === 'localStorage' ? window.localStorage : window.sessionStorage
}
function getTrackedWebStorageKeys(storageObj: Storage): Set<string> {
    const trackedKeysValue = storageObj.getItem('trackedStorageKeys')
    return new Set(trackedKeysValue ? JSON.parse(trackedKeysValue) : [])
}
function trackWebStorageKey(key: string, storageObj: Storage) {
    const trackedKeys = getTrackedWebStorageKeys(storageObj)
    trackedKeys.add(key)
    storageObj.setItem('trackedStorageKeys', JSON.stringify([...trackedKeys]))
}
function removeTrackedWebStorageKey(key: string, storageObj: Storage) {
    const trackedKeys = getTrackedWebStorageKeys(storageObj)
    trackedKeys.delete(key)
    storageObj.setItem('trackedStorageKeys', JSON.stringify([...trackedKeys]))
}

function webStorageSet(key: string, value: string, type: webStorageType): void {
    if (getLocalStorageAvailable()) {
        const storage = getWebStorageObj(type)
        storage.setItem(key, value)
        trackWebStorageKey(key, storage)
    }
}

function webStorageGet(key: string, type: webStorageType): string | null {
    if (getLocalStorageAvailable()) {
        const storage = getWebStorageObj(type)
        return storage.getItem(key)
    }
    return null
}

function webStorageRemove(key: string, type: webStorageType): void {
    if (getLocalStorageAvailable()) {
        const storage = getWebStorageObj(type)
        storage.removeItem(key)
        removeTrackedWebStorageKey(key, storage)
    }
}

export function webStorageClear(
    type: webStorageType,
    keysToKept: string[] = [],
): void {
    if (getLocalStorageAvailable()) {
        const storage = getWebStorageObj(type)
        const trackedKeys = getTrackedWebStorageKeys(storage)
        Array.from(trackedKeys)
            .filter((k) => keysToKept.indexOf(k) === -1)
            .forEach((key) => webStorageRemove(key, type))
    }
}

export const localStorageSet = (key: string, value: string): void =>
    webStorageSet(key, value, 'localStorage')

export const localStorageGet = (key: string): string | null =>
    webStorageGet(key, 'localStorage')

export const localStorageRemove = (key: string): void =>
    webStorageRemove(key, 'localStorage')

export const sessionStorageSet = (key: string, value: string): void =>
    webStorageSet(key, value, 'sessionStorage')

export const sessionStorageGet = (key: string): string | null =>
    webStorageGet(key, 'sessionStorage')

export const sessionStoragePop = (key: string): string | null => {
    const value = webStorageGet(key, 'sessionStorage')
    webStorageRemove(key, 'sessionStorage')
    return value
}

export const sessionStorageRemove = (key: string): void =>
    webStorageRemove(key, 'sessionStorage')

export const useStatefulSessionStorage = (
    key: string,
): [string | null, (newValue: string) => void] => {
    const [value, _setValue] = useState<string | null>(null)

    useEffect(() => {
        const value = sessionStorageGet(key)
        _setValue(value)
    }, [key])

    const setValue = (value: string) => {
        sessionStorageSet(key, value)
        _setValue(value)
    }

    return [value, setValue]
}
